<script lang="ts">
import { defineComponent } from 'vue'
import { isCurrent, type NavLink, useNavState } from '../../composables/nav'

export default defineComponent({
  props: {
    items: { type: Array, required: true },
  },
  setup(props) {
    const { slideoverMenu, expanded } = useNavState()

    const buttonUi = {
      padding: { '2xs': 'p-2' },
      gap: { '2xs': 'gap-x-2' },
      variant: {
        solid: 'hover:bg-forgd-primary-500 bg-forgd-primary-900',
      },
    }

    const popoverUi = {
      ring: 'ring-forgd-primary-800',
      arrow: {
        base: 'invisible before:visible before:block before:rotate-45 before:z-[-1] before:w-2 before:h-2',
        ring: 'before:ring-1 before:ring-forgd-primary-900 dark:before:ring-gray-900',
        rounded: 'before:rounded-sm',
        background: 'before:bg-forgd-primary-900',
        shadow: 'before:shadow',
        placement: 'before:translate-x-[15px]',
      },
    }
    return {
      items: props.items as NavLink[],
      isCurrent,
      buttonUi,
      popoverUi,
      slideoverMenu,
      expanded,
    }
  },
})
</script>

<template>
  <ul data-el="AppMenuExpandableNavigationList" class="space-y-2">
    <li v-for="(item, key) in items" :key="key">
      <template v-if="!expanded">
        <UiPopover v-if="item.to" mode="hover" :popper="{ placement: 'right', offsetDistance: 15, arrow: true }" :ui="popoverUi">
          <UButton size="2xs" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :ui="buttonUi" class="relative" v-on="item.on ?? {}">
            <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
            <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
            <svg v-if="isCurrent(item, item.isStrict || false)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="absolute top-1 right-1">
              <circle cx="4" cy="4" r="4" fill="#2D73FF" />
            </svg>
          </UButton>
          <template #panel>
            <div class="text-left px-2 py-5 bg-forgd-primary-900 text-white text-xs">
              <ULink :to="item.to" class="px-3 whitespace-nowrap font-semibold" v-on="item.on ?? {}">
                {{ item.label }}
              </ULink>
            </div>
          </template>
        </UiPopover>
        <UButton v-else size="2xs" :loading="item.loading" :to="item.children ? undefined : item.to" class="relative" active-class="bg-forgd-primary-500" :ui="buttonUi" v-on="item.on ?? {}">
          <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
          <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
          <svg v-if="isCurrent(item, false)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="absolute top-1 right-1">
            <circle cx="4" cy="4" r="4" fill="#2D73FF" />
          </svg>
        </UButton>
      </template>
      <template v-else>
        <UButton size="2xs" :external="item.external" :target="item.external ? '_blank' : undefined" :loading="item.loading" class="w-full" :class="slideoverMenu === item.key ? '!bg-forgd-primary-500' : ''" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :ui="buttonUi" v-on="item.on ?? {}">
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center gap-2 w-full">
              <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
              <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
              <div v-if="item.label" class="font-normal">
                {{ item.label }}
              </div>
            </div>
            <svg v-if="isCurrent(item, item.isStrict || false)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="mr-1">
              <circle cx="4" cy="4" r="4" fill="#2D73FF" />
            </svg>
          </div>
        </UButton>
      </template>
    </li>
  </ul>
</template>
