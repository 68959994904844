<script setup lang="ts">
import { mergeConfig } from '#ui/utils'
import { TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  createReusableTemplate,
  createTemplatePromise,
  useStorage,
} from '@vueuse/core'
import { computed } from 'vue'
import { isCurrent, useNavState } from '../../composables/nav'
// @ts-expect-error untyped
import appConfig from '#build/app.config'
import { slideover } from '#ui/ui.config'

defineOptions({
  inheritAttrs: false,
})
const tracer = useTracer()

const { slideoverMenu: modelValue, expanded } = useNavState()

const config = mergeConfig(appConfig.ui.strategy, appConfig.ui.slideover, slideover)
const { ui, attrs } = useUI('slideover', computed(() => ({
  wrapper: `transform ${expanded.value ? 'translate-x-[260px]' : 'translate-x-[52px]'} transition-all`,
  width: 'max-w-[280px]',
  overlay: {
    background: 'bg-gray-900/10',
  },
})), config, ref('')) as Record<string, Ref<Record<string, any>>>

const auth = useAuth()
const runtimeConfig = useRuntimeConfig()
const { project } = storeToRefs(auth)
const teammateAccessWall = useTeammateAccessWall()
const skipGoogleSheetConfirm = useStorage<boolean>('forgd:nav:google-sheet-confirm', false)
const toolsAndServicesTab = useStorage<number>('forgd:nav:tools-services-tab', project.value?.tokenListed ? 1 : 0)

const transitionClass = computed(() => {
  const enterFrom = ui.value.translate.left
  const leaveTo = ui.value.translate.left
  return {
    ...ui.value.transition,
    enterFrom,
    enterTo: ui.value.translate.base,
    leaveFrom: ui.value.translate.base,
    leaveTo,
  }
})

const GoogleSheetsConfirmModal = createTemplatePromise<boolean>()
const [DefineNavList, NavList] = createReusableTemplate<{ items: { to: string, label: string, icon?: any }[] }>()
const [DefineNavTitle, NavTitle] = createReusableTemplate<{ label: string, to?: string, icon?: string }>()

async function handleLinkClick(to: string) {
  tracer.custom('nav_link_click', { to })
  if (to === '/partner-activity-lead/amm-2' && !auth.isOrganizationOwner) {
    teammateAccessWall.show()
    return
  }

  if (to.startsWith('https://docs.google.com/spreadsheets')) {
    if (skipGoogleSheetConfirm.value || await GoogleSheetsConfirmModal.start()) {
      window.open(to, '_blank')
    }
    return
  }
  to.startsWith('http') ? window.open(to, '_blank') : navigateTo(to)
}
</script>

<template>
  <DefineNavList v-slot="{ items }">
    <ul>
      <li v-for="(child, childKey) in items" :key="childKey">
        <ULink :to="child.to" class="flex items-center justify-between w-full py-2 px-3 text-forgd-gray-600 hover:bg-forgd-neutral-400 transition rounded" @click.prevent="handleLinkClick(child.to)">
          <div>{{ child.label }}</div>
          <UIcon v-if="child.to?.startsWith('http')" name="i-heroicons-arrow-up-right" />
          <svg v-else-if="isCurrent(child, true)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="ml-1">
            <circle cx="4" cy="4" r="4" fill="#2D73FF" />
          </svg>
        </ULink>
      </li>
    </ul>
  </DefineNavList>
  <DefineNavTitle v-slot="{ label, icon }">
    <div class="flex items-center text-forgd-primary-900 gap-2 mb-2">
      <UIcon v-if="typeof icon === 'string'" :name="icon" class="w-5 h-5 text--bgd-200" />
      <div class="font-semibold">
        {{ label }}
      </div>
    </div>
  </DefineNavTitle>
  <TransitionRoot as="template" appear :show="modelValue !== null">
    <div :class="[ui.wrapper]" v-bind="attrs">
      <TransitionChild as="template" appear v-bind="transitionClass">
        <div data-el="AppMenuSlideover" class="bg-white h-full border-r border-forgd-gray-300" :class="[ui.base, ui.width, ui.background, ui.ring, ui.padding]">
          <TransitionGroup name="slideovers-animation" tag="div">
            <div :key="String(modelValue)" class="text-xs relative max-h-screen overflow-y-auto light-scrollbar z-10">
              <div v-if="modelValue === 'tools-and-services'">
                <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
                  <div class="text-sm mb-2 font-semibold text-forgd-primary-900">
                    <div>Tools & Services</div>
                  </div>
                  <UTabs
                    v-model="toolsAndServicesTab"
                    class="w-full mb-0 bg-white space-y-0"
                    :ui="{ list: { background: 'bg-white ring-forgd-gray-350 ring-1', marker: { background: 'bg-forgd-accent-900' }, tab: { active: 'text-white', size: 'text-xs', font: 'font-semibold' } } }"
                    :items="[{ label: 'Pre-TGE Token' }, { label: 'Listed Token' }]"
                  />
                </div>
                <div class="p-4">
                  <nav v-if="toolsAndServicesTab === 0" aria-label="Pre TGE Token Navigation" class="space-y-4">
                    <div v-for="(item, key) in preTgeNav" :key="key">
                      <NavTitle :label="item.label" :to="item.to" :icon="item.icon" />
                      <NavList :items="item.children" />
                    </div>
                  </nav>
                  <nav v-else-if="toolsAndServicesTab === 1" aria-label="Listed Token Navigation" class="space-y-4">
                    <div v-for="(item, key) in listedTokenNav" :key="key">
                      <NavTitle :label="item.label" :to="item.to" :icon="item.icon" />
                      <NavList :items="item.children" />
                    </div>
                  </nav>
                </div>
              </div>
              <nav v-else aria-label="Forgd Academy Navigation">
                <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
                  <div class="text-sm font-semibold text-forgd-primary-900">
                    <div>Forgd Academy</div>
                  </div>
                </div>
                <div v-for="(item, key) in forgdAcademyNav" :key="key" class="p-4">
                  <NavTitle :label="item.label" :to="item.to" :icon="item.icon" />
                  <NavList :items="item.children" />
                </div>
              </nav>
            </div>
          </TransitionGroup>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
  <GoogleSheetsConfirmModal v-slot="{ resolve }">
    <UiModal
      is-open
      @close="resolve(false)"
    >
      <div>
        <UiStack>
          <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mb-5">
              <path d="M33.3332 11.5701V32.6666C33.3332 34.8757 31.5423 36.6666 29.3332 36.6666H10.6665C8.45737 36.6666 6.6665 34.8757 6.6665 32.6666V7.33325C6.6665 5.12411 8.45737 3.33325 10.6665 3.33325H24.3507M33.3332 11.5701L24.3507 3.33325M33.3332 11.5701H24.3507V3.33325M20.1402 19.2921H12.2805V23.2818M20.1402 19.2921H27.9998V23.2818M20.1402 19.2921V27.2715M20.1402 27.2715H27.9998V23.2818M20.1402 27.2715H12.2805V23.2818M27.9998 23.2818H12.2805" stroke="#057A55" stroke-width="1.5" />
            </svg>
            <div class="mb-3 font-semibold text-xl text-primary-900">
              You are about to open a Forgd workbook in Google Sheets in a new tab
            </div>
            <div class="mb-2 text-gray-600 text-base">
              Create a copy of the file on your local device (File > Make a Copy) before editing. Your session on Forgd will remain active.
            </div>
            <div class="text-gray-600 text-base">
              Would you like to continue?
            </div>
          </div>
          <div class="flex items-center w-full gap-3">
            <UiButton variant="outline" class="w-1/2" @click="resolve(false)">
              Cancel
            </UiButton>
            <UiButton variant="solid" class="w-1/2" @click="resolve(true)">
              Yes, Continue
            </UiButton>
          </div>
          <UiRule />
          <UCheckbox v-model="skipGoogleSheetConfirm" label="Don't show this message again" />
          <UiAlert type="info">
            <template #title>
              If you have questions on how to edit the prototype, <NuxtLink :to="runtimeConfig.public.consultationUrl" target="_blank" class="underline text-forgd-primary-300">
                schedule a consultation
              </NuxtLink>.
            </template>
          </UiAlert>
        </UiStack>
      </div>
    </UiModal>
  </GoogleSheetsConfirmModal>
</template>

<style>
.slideovers-animation-move,
.slideovers-animation-enter-active,
.slideovers-animation-leave-active {
  transition: all 0.3s ease;
  overflow: hidden;
}
.slideovers-animation-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.slideovers-animation-enter-from {
  opacity: 0;
  transform: translateX(30px);
}
.slideovers-animation-leave-active {
  position: absolute;
}
</style>
